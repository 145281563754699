import {EnvProperties} from "../app/openaireLibrary/utils/properties/env-properties";
import {
  common,
  commonProd
} from "../app/openaireLibrary/utils/properties/environments/environment";

let props: EnvProperties = {
  dashboard: "irish",
  adminToolsAPIURL: "https://services.openaire.eu/irish-monitor-service/",
  monitorServiceAPIURL: "https://services.openaire.eu/irish-monitor-service/",
  monitorStatsFrameUrl:"https://services.openaire.eu/stats-tool/",
  piwikSiteId: "787",
  enablePiwikTrack: true,
  adminToolsPortalType: 'country',
  adminToolsCommunity: 'irish',
  domain: "https://oamonitor.ireland.openaire.eu",
  zenodoDumpUrl: "https://doi.org/10.5281/zenodo.10474106",
  logServiceUrl: "https://oamonitor.ireland.openaire.eu/",
  logFilesPath: "/srv/static/nodejs/irish-monitor-action-logs/",
  matomoLogFilesPath: "/srv/www/static/oamonitor.ireland.openaire.eu/stats/",
  myClaimsLink:"/participate/myclaims",
  loginServiceURL: ["https://services.openaire.eu/irish-monitor-service/", <string>commonProd.loginServiceURL],
  afterLoginRedirectLink: "/user-policy",
  helpdeskEmail: 'oamonitor.ireland@openaire.eu',
  admins: ['oamonitor.ireland@openaire.eu'],
  useHelpTexts: true,
  orcidDiscoverLinksPage: "/orcid-discover-links"

}


export let properties: EnvProperties = {
  ...common, ...commonProd, ...props
}
